import { useMediaQuery } from "react-responsive";
import { SectionMainTitle, SectionTitle } from "../../common";
import { DescriptionItem } from "../../constants/culture";
import {
  Content,
  ContentContainer,
  Description,
  ImageBox,
  Img,
  SectionWrapper,
  TextBox,
  Title,
  TitleBox,
  TitleBox2,
} from "./styled";
import React from "react";

export interface CultureContentItem {
  title: string;
  image: string;
  description: DescriptionItem[];
}

interface CultureProps {
  culture: CultureContentItem[];
}

export const CultureSection = React.forwardRef<HTMLDivElement, CultureProps>(
  ({ culture }, ref) => {
    const isMobile = useMediaQuery({
      query: "(max-width: 767px)",
    });
    return (
      <SectionWrapper ref={ref}>
        <TitleBox>
          <SectionTitle marginBottom="20px">Culture</SectionTitle>
        </TitleBox>
        <TitleBox2>
          <SectionMainTitle marginBottom="60px">
            몰입과 워라밸 실현을 위한 <br /> 복지제도
          </SectionMainTitle>
        </TitleBox2>
        <ContentContainer>
          {culture.map(({ title, description, image }, i) => {
            return (
              <Content>
                <TextBox>
                  {isMobile ? (
                    <Title style={i === 4 ? { width: "160px" } : {}}>
                      {title}
                    </Title>
                  ) : (
                    <Title>{title}</Title>
                  )}
                  {description.map(({ text }) => {
                    return <Description>{text}</Description>;
                  })}
                </TextBox>
                <ImageBox>
                  <Img src={image} />
                </ImageBox>
              </Content>
            );
          })}
        </ContentContainer>
      </SectionWrapper>
    );
  }
);

import styled from "styled-components";
import { colors } from "../../../styles";

export const AboutSectionWrapper = styled.div`
  display: flex;
  padding: 140px 120px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 60px 24px 115px 24px;
    justify-content: center;
    align-items: center;
  }
`;

export const IntroductionTextBox = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    margin-bottom: 60px;
    align-items: center;
  }
  @media (max-width: 767px) {
    width: 312px;
    align-items: flex-start;
  }
`;

export const AboutTextWrapper = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;
export const ImageBox = styled.img`
  max-width: 480px;
  max-height: 420px;
  width: 100%;
  height: 100%;
  aspect-ratio: auto 1/1;
  border-radius: 20px;
  background-color: ${colors.whitePurple};

  @media (max-width: 767px) {
    max-width: 312px;
    max-height: 273px;
  }
`;

export const MainTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

import {
  ApplePlay,
  BackImage1,
  BackImage2,
  BackImage3,
  BackImage4,
  GooglePlay,
  HoverImage1,
  HoverImage2,
  HoverImage3,
  HoverImage4,
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Mobile1,
  Mobile2,
  Mobile3,
  Mobile4,
  Mobile5,
} from "../../assets";
import { ServiceContentItem } from "../sections/OurServiceSection/index";

export interface PlayItem {
  title: string;
  image?: string;
  href: string;
}

export const ServiceList: ServiceContentItem[] = [
  {
    type: 'main',
    title: "뽑기방 킹덤",
    gameType: "캐주얼 | 모바일",
    making: "",
    dist: "개발 및 배급 : ㈜나인투랩스",
    image: Image1,
    backImage: BackImage1,
    hoverImage: HoverImage1,
    mobileImage: Mobile1,
    playButton: [
      {
        title: "Google play",
        image: GooglePlay,
        href: "https://play.google.com/store/apps/details?id=com.ninetwolabs.drawshop",
      },
      {
        title: "App Store",
        image: ApplePlay,
        href: "https://apps.apple.com/kr/app/id1541330064",
      },
    ],
  },
  {
    type: 'main',
    title: "Drawshop Kingdom Reverse",
    gameType: "P2E NFT | 모바일 PC",
    making: "개발 : ㈜나인투랩스",
    dist: "배급 : Nolatency Limited",
    image: Image2,
    backImage: BackImage2,
    hoverImage: HoverImage2,
    mobileImage: Mobile2,
    playButton: [
      {
        title: "Google play",
        image: GooglePlay,
        href: "https://play.google.com/store/apps/details?id=io.comakers.dkr",
      },
    ],
  },
  {
    type: 'main',
    title: "Hypermarket",
    gameType: "NFT 마켓플레이스 플랫폼 | 모바일 PC",
    making: "개발 : ㈜나인투랩스",
    dist: "배급 : Nolatency Limited",
    image: Image3,
    backImage: BackImage3,
    hoverImage: HoverImage3,
    mobileImage: Mobile3,
    playButton: [
      {
        title: "Website",
        image: undefined,
        href: "https://hypermarket.gg",
      },
    ],
  },
  {
    type: 'other',
    title: "Drawshop Universe",
    gameType: "캐릭터 IP",
    making: "  IP 개발 : ㈜나인투랩스 ",
    dist: "IP 라이선싱 : Nolatency Limited",
    image: Image4,
    backImage: "",
    hoverImage: "",
    mobileImage: Mobile4,
    playButton: [
      {
        title: "Website",
        image: "",
        href: "",
      },
    ],
  },
  {
    type: 'main',
    title: "좀비 키우기 : 방치형 RPG",
    gameType: "방치형 RPG | 모바일",
    making: "",
    dist: "개발 및 배급 : ㈜나인투랩스",
    image: Image5,
    backImage: BackImage4,
    hoverImage: HoverImage4,
    mobileImage: Mobile5,
    playButton: [
      {
        title: "Google play",
        image: GooglePlay,
        href: "https://play.google.com/store/apps/details?id=com.ninetwolabs.loz",
      },
      {
        title: "App Store",
        image: ApplePlay,
        href: "https://apps.apple.com/kr/app/id6478361840",
      },
    ],
  },
];

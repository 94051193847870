import { styled } from "styled-components";
import { colors } from "../../../styles";

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${colors.white};
`;

export const ServiceContainer = styled.div`
  width: 100%;
`;

export const MarginWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 80px 120px 144px 120px;

  @media (max-width: 767px) {
    padding: 0 24px 60px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const BackgroundImage = styled.div<{
  image: string;
  hoverImage: string;
}>`
  background-image: url(${(props) => props.image});
  background-size: cover;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;

  &:hover {
    background-image: url(${(props) => props.hoverImage});
    transition: 0.7s;
    opacity: 1;
  }

  @media (max-width: 767px) {
    background-image: none;
    opacity: 1;
    &:hover {
      background-image: none;
      transition: none;
    }
  }
`;

export const SectionTitleBox = styled.div`
  padding: 140px 0 0 120px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 60px 24px 10px 24px;
  }
`;
export const ContentTextBox = styled.div`
  display: flex;

  @media (max-width: 1260px) {
    margin-top: 40px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: none;
    padding-top: 0;
  }
`;

export const ImageBox = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 20px;
  background-color: ${colors.gray};
  @media (max-width: 767px) {
    width: 100%;
    max-width: 312px;
    height: 174px;
  }
`;

export const TextBox = styled.div`
  display: flex;
  padding: 0 132px 0 32px;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1260px) {
    padding-left: 32px;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 8px;
  }
`;

export const Gametype = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 767px) {
  }
`;

export const Making = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.darkGray};
`;

export const Dist = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.darkGray};
`;

export const PlayButton = styled.a`
  cursor: pointer;
  width: 188px;
  height: 56px;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: black;
  margin-right: 20px;
  border-radius: 8px;
  z-index: 5;
  @media (max-width: 767px) {
    width: 148px;
    height: 48px;
    background-color: #f2f2f2;
    top: 190px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
  }
`;

export const Img = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
  }
`;

export const PlayBox = styled.div`
  padding-top: 40px;
  display: flex;
  @media (max-width: 767px) {
    width: 312px;
    padding-top: 12px;
  }
`;

export const OtherServiceSection = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  padding: 80px 120px 140px 120px;
  grid-template-columns: repeat(auto-fit, minmax(510px, max-content));

  @media (max-width: 900px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    padding: 0 24px 80px 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
  }
`;

export const MobileContentTextBox = styled.div`
  width: 312px;
  height: 174px;
  border-radius: 10px;
  background-color: ${colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 225px;
`;

export const ComingSoonBox = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 20px;
  background-color: ${colors.gray};

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 312px;
    height: 174px;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-top: 60px;
  }
`;

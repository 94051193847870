import {useMediaQuery} from "react-responsive";
import {SectionTitle} from "../../common";
import {
  Content,
  ContentBox,
  ImageBox,
  Img,
  MarginWrapper,
  Moment, PaginationBox,
  SectionWrapper,
  TextBox,
  Title,
  TitleBox,
} from "./styled";
import {useEffect, useState} from "react";

export interface NewsContentItem {
  title: string;
  image: string;
  date: string;
  href: string;
}

interface NewsProps {
  news: NewsContentItem[];
}

const ITEM_PER_PAGE = 6;
export const NewsSection: React.FC<NewsProps> = ({news}) => {

  news.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  useEffect(() => {
    setMaxPage(Math.ceil(news.length / ITEM_PER_PAGE));
  }, [news]);

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <SectionWrapper>
      <MarginWrapper>
        {isMobile ? (
          <TitleBox>
            <SectionTitle marginBottom="48px">News</SectionTitle>
          </TitleBox>
        ) : (
          <SectionTitle marginBottom="34px">News</SectionTitle>
        )}
        <ContentBox>
          {
            news
              .slice(currentPage * ITEM_PER_PAGE, (currentPage + 1) * ITEM_PER_PAGE)
              .map(({
                      title,
                      image,
                      date,
                      href
                    }) => {
                return (
                  <Content>
                    <ImageBox href={href} target="_blank">
                      <Img src={image}/>
                    </ImageBox>
                    <TextBox>
                      <Title>{title}</Title>
                      <Moment>{date}</Moment>
                    </TextBox>
                  </Content>
                );
              })}
        </ContentBox>
        <PaginationBox>
          {Array.from({length: maxPage}).map((_, i) => {
            return (
              <div className={'item '+(i === currentPage ? 'active' : '')}
                key={i}
                onClick={() => setCurrentPage(i)}
              >
                {i + 1}
              </div>
            );
          })}
        </PaginationBox>
      </MarginWrapper>
    </SectionWrapper>
  );
};

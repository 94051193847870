import styled from "styled-components";
import { colors } from "../../../styles";
export const ContentBox = styled.div`
  padding: 0 120px;
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(360deg, #222222 0%, rgba(34, 34, 34, 0) 100%);

  @media (max-width: 767px) {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #222222 0%, rgba(34, 34, 34, 0) 100%);
  }
`;
export const TitleWrapper = styled.div`
  width: 523px;
  height: 104px;
  color: ${colors.white};

  @media (max-width: 767px) {
    width: 320px;
  }
`;

export const MainTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

import { SectionTitle } from "../../common";
import { PlayItem, ServiceList } from "../../constants";
import {
  BackgroundImage,
  ComingSoonBox,
  ContentTextBox,
  Dist,
  Gametype,
  ImageBox,
  Img,
  Making,
  MarginWrapper,
  OtherServiceSection,
  PlayBox,
  PlayButton,
  SectionTitleBox,
  SectionWrapper,
  ServiceContainer,
  TextBox,
  Title,
} from "./styled";
import { useMediaQuery } from "react-responsive";
import React from "react";

export interface ServiceContentItem {
  title: string;
  gameType: string;
  making: string;
  dist: string;
  image?: string;
  backImage: string;
  hoverImage: string;
  mobileImage: string;
  playButton: PlayItem[];
  type: 'main'| 'other'
}
interface ServiceProps {
  service: ServiceContentItem[];
}

export const OurServiceSection = React.forwardRef<HTMLDivElement, ServiceProps>(
  ({ service }, ref) => {
    const Services = ServiceList.filter(x => x.type === 'main');
    const OtherServices = ServiceList.filter(x => x.type === 'other');
    const isMobile = useMediaQuery({
      query: "(max-width: 767px",
    });
    return (
      <SectionWrapper ref={ref}>
        <SectionTitleBox>
          {isMobile ? (
            <SectionTitle marginBottom="0px">Our Service</SectionTitle>
          ) : (
            <SectionTitle marginBottom="48px">Our Service</SectionTitle>
          )}
        </SectionTitleBox>
        <ServiceContainer>
          {Services.map(
            (
              {
                title,
                gameType,
                making,
                dist,
                image,
                backImage,
                playButton,
                hoverImage,
                mobileImage,
              },
              i
            ) => {
              return (
                <>
                  <BackgroundImage
                    image={`${backImage}`}
                    hoverImage={`${hoverImage}`}
                  >
                    <MarginWrapper>
                      {isMobile ? (
                        <ContentTextBox>
                          <ImageBox src={mobileImage} />
                          <TextBox>
                            <Title>{title}</Title>
                            <Gametype>{gameType}</Gametype>
                            <Making>{making}</Making>
                            <Dist>{dist}</Dist>
                          </TextBox>
                        </ContentTextBox>
                      ) : (
                        <ContentTextBox>
                          <ImageBox src={image} />
                          <TextBox>
                            <Title>{title}</Title>
                            <Gametype>{gameType}</Gametype>
                            <Making>{making}</Making>
                            <Dist>{dist}</Dist>
                          </TextBox>
                        </ContentTextBox>
                      )}
                      <PlayBox>
                        {playButton.map(({ title, href, image }) => {
                          return (
                            <PlayButton href={href} target="_blank">
                              {image ? <Img src={image} /> : null}
                              {title}
                            </PlayButton>
                          );
                        })}
                      </PlayBox>
                    </MarginWrapper>
                  </BackgroundImage>
                </>
              );
            }
          )}
        </ServiceContainer>
        <OtherServiceSection>
          {OtherServices.map(
            ({ title, gameType, making, dist, image, mobileImage }) => {
              return (
                <>
                  <ContentTextBox>
                    {isMobile ? (
                      <ImageBox src={mobileImage} />
                    ) : (
                      <ImageBox src={image} />
                    )}
                    <TextBox>
                      <Title>{title}</Title>
                      <Gametype>{gameType}</Gametype>
                      <Making>{making}</Making>
                      <Dist>{dist}</Dist>
                    </TextBox>
                  </ContentTextBox>
                </>
              );
            }
          )}
          <ContentTextBox>
            {isMobile ? (
              <>
                <ComingSoonBox>Coming Soon</ComingSoonBox>
              </>
            ) : (
              <>
                <ComingSoonBox />
                <TextBox style={{ justifyContent: "flex-start" }}>
                  <Title>Coming Soon</Title>
                </TextBox>
              </>
            )}
          </ContentTextBox>
        </OtherServiceSection>
      </SectionWrapper>
    );
  }
);

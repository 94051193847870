import { createGlobalStyle } from "styled-components";

import { reset } from "./reset";

export const GlobalStyle = createGlobalStyle`
${reset}

* {
  box-sizing: border-box;
  ::-webkit-scrollbar{
    display: none;
  }
  &:focus {
    outline: none;
  }
}


#app,
#root,
#__next {
  font-family: Pretendard;
font-size: 16px;
font-weight: 600;
line-height: 24px;
text-align: left;
color: #222222;
}

html,body{

  img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
  ::selection {

background: none;

  }
}
`;

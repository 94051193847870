import React, { useRef } from "react";
import { HomeWrapper } from "./styled";
import { AboutSection } from "../../components/sections/AboutSection/ index";
import { VisionSection } from "../../components/sections/VisionSection/index";
import { BuisinessSection } from "../../components/sections/BusinessSection";
import { CultureSection } from "../../components/sections/CultureSection";
import { ContentList } from "../../components/constants/culture";
import { OurServiceSection } from "../../components/sections/OurServiceSection";
import {
  BusinessTextList,
  MenuList,
  ServiceList,
} from "../../components/constants";
import { PartnerSection } from "../../components/sections/PartnerSection";
import { PartnerList } from "../../components/constants/partner";
import { NewsSection } from "../../components/sections/NewsSection";
import { NewsList } from "../../components/constants/news";
import { ContactList } from "../../components/constants/contact";
import { ContactSection } from "../../components/sections/ContactSection";
import { Navbar } from "../../components/common";
import { Footer } from "../../components/common/Footer";
import MainSection from "../../components/sections/MainSection/ index";
export const Home: React.FC = () => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  // const handleOnClickMenu = (index: number) => {
  //   const currentSection = sectionRefs.current[index];
  //   if (!currentSection) return;

  //   currentSection.scrollIntoView({ behavior: "smooth" });
  // };

  const handleOnClickMenu = (index: number) => {
    const currentSection = sectionRefs.current[index];
    if (!currentSection) return;

    const offset = -50;
    const scrollToY =
      currentSection.getBoundingClientRect().top + window.scrollY + offset;

    window.scrollTo({ top: scrollToY, behavior: "smooth" });
  };

  return (
    <HomeWrapper>
      <Navbar menu={MenuList} onClickMenu={handleOnClickMenu} />
      <MainSection />
      <AboutSection ref={(ref) => (sectionRefs.current[0] = ref)} />
      <VisionSection ref={(ref) => (sectionRefs.current[1] = ref)} />
      <BuisinessSection buisiness={BusinessTextList} />
      <OurServiceSection
        service={ServiceList}
        ref={(ref) => (sectionRefs.current[2] = ref)}
      />
      <CultureSection
        culture={ContentList}
        ref={(ref) => (sectionRefs.current[3] = ref)}
      />
      <PartnerSection partner={PartnerList} />
      <NewsSection news={NewsList} />
      <ContactSection
        contact={ContactList}
        ref={(ref) => (sectionRefs.current[4] = ref)}
      />
      <Footer />
    </HomeWrapper>
  );
};

import { styled } from "styled-components";
import { colors } from "../../../styles";

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  padding: 140px 140px;

  @media (max-width: 767px) {
    padding: 60px 24px 80px 24px;
  }
`;

export const TitleBox = styled.div`
  position: relative;
  right: 20px;
`;

export const ContentBox = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, max-content));
  flex-wrap: wrap;
  flex-direction: row;
  gap: 60px 120px;

  @media (max-width: 1100px) {
    gap: 40px;
  }

  @media (max-width: 767px) {
    width: 280px;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
`;

export const Img = styled.img`
  width: 200px;
  height: 100px;

  @media (max-width: 900px) {
    width: 160px;
    height: 80px;
  }

  @media (max-width: 767px) {
    width: 120px;
    height: 60px;
  }
`;

export const MainTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
`;

export const MainTitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  right: 20px;
`;

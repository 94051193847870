import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components";
import { Home } from "./pages";

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

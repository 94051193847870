import { Clock, Coffee, Heart, People, Vacation } from "../../assets";
import { CultureContentItem } from "../sections/CultureSection";

export interface DescriptionItem {
  text: string;
}

export const ContentList: CultureContentItem[] = [
  {
    title: "여유 있는 근무 시간",
    image: Clock,
    description: [
      {
        text: "시차출퇴근제 운영",
      },
      {
        text: "(9시 ~ 11시 / 본인 선택 출근제)",
      },
      {
        text: "초과근로 없는 기업 - 0시간제도 운영",
      },
    ],
  },
  {
    title: "미니 카페",
    image: Coffee,
    description: [
      {
        text: "제로음료 / 커피 / 따뜻한 차",
      },
      {
        text: "사무실 내 무제한 제공",
      },
    ],
  },
  {
    title: "관광공사 연계 휴가지원 포인트",
    image: Vacation,
    description: [
      {
        text: "총 40만 포인트 제공",
      },
    ],
  },
  {
    title: "가족 사랑 지원",
    image: Heart,
    description: [
      {
        text: "생일 휴가 부여",
      },
      {
        text: "경조사 휴가 및 특별 안식 휴가 부여",
      },
      {
        text: "직계 가족 경사 지원 및 명절 선물 지급/제공",
      },
      {
        text: "가족사랑의 날 (PC-OFF제도) 운영",
      },
    ],
  },
  {
    title: "외부 전문 노무사 1:1 근로자 상담센터",
    image: People,
    description: [
      {
        text: "근무 및 심리 관련 근로자 상담센터 상시 운영",
      },
    ],
  },
];

import React from "react";
import { LayoutContainer, LayoutWrapper } from "./styled";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <LayoutContainer>
      <LayoutWrapper>{children}</LayoutWrapper>
    </LayoutContainer>
  );
};

import { styled } from "styled-components";
import { colors } from "../../../styles";

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 140px 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${colors.white};

  @media (max-width: 767px) {
    padding: 60px 24px 80px 24px;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(560px, max-content));
  gap: 40px 55px;

  @media (max-width: 767px) {
    justify-content: center;
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(312px, max-content));
  }
`;

export const TitleBox = styled.div`
  @media (max-width: 767px) {
    position: relative;
    right: 120px;
  }
`;

export const TitleBox2 = styled.div`
  @media (max-width: 767px) {
    position: relative;
    right: 10px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 560px;
  min-height: 200px;
  justify-content: space-between;
  border-radius: 20px;
  background-color: ${colors.gray};
  padding: 30px 30px 30px 40px;

  @media (max-width: 767px) {
    flex-direction: column;
    min-width: 312px;
    min-height: auto;
    padding: 20px 30px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
`;

export const Description = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${colors.darkGray};

  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

export const Img = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 767px) {
    width: 48px;
    height: 48px;
    margin-top: 20px;
  }
`;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const TextBox = styled.div`
  height: auto;
`;

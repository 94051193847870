import { NavbarMenuItem } from "../common";

export const MenuList: NavbarMenuItem[] = [
  {
    text: "회사 소개",
    key: 1,
  },
  {
    text: "비전",
    key: 2,
  },
  {
    text: "서비스",
    key: 3,
  },
  {
    text: "문화",
    key: 4,
  },
  {
    text: "문의",
    key: 5,
  },
];

import { styled } from "styled-components";
import { VisionPNG } from "../../../assets";

export const VisionSectionWrapper = styled.div`
  width: 100%;
  background-image: url(${VisionPNG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MarginWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 140px 120px;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    padding: 60px 24px 80px 24px;
  }
`;

export const SectionTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`;

export const VisionTextWrapper = styled.p`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

export const colors = {
  white: "#ffffff",
  purple: "#7682E7",
  gray: "#F2F2F2",
  grayScale: "#595959",
  whitePurple: "#DAD3EE",
  whiteBlue: "#F7F7FD",
  blue: "#D3D9EE",
  darkGray: "#666666",
};

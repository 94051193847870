import { BusinessTextItem } from "../sections/BusinessSection";

export const BusinessTextList: BusinessTextItem[] = [
  {
    text: "벤처기업협회, 벤처인증기업 (혁신성장부문)",
  },
  {
    text: "ISO 9001, 14001, 45001 인증기업",
  },
  {
    text: "여성가족부, 가족친화우수기업",
  },
  {
    text: "고용노동부, 일생활균형우수기업",
  },
  {
    text: "서울특별시, 2024 하이서울기업",
  },
  {
    text: "고용노동부, 근무혁신인센티브제 (S등급)",
  },
  {
    text: "과학기술정보통신부, 2022 클라우드 서비스 사용 우수기업",
  },
  {
    text: "한국관광공사, 2022, 2023, 2024 근로자휴가지원사업 참여기업",
  },
  {
    text: "중소벤처기업부, 성과공유기업",
  },
  {
    text: "중소벤처기업부, 2024 인재육성형 중소기업",
  },
  {
    text: "이외 상표 및 저작권, 블록체인/NFT 기술특허 다수 확보",
  },
];

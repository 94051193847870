import {NewsContentItem} from "../sections/NewsSection";



export const NewsList: NewsContentItem[] = [
  {
    title: "2023 인디크래프트' TOP 50 우수 개발사 근황 및 성과 공개",
    image: '/img/News/img2.png',
    date: "2023.11.29",
    href: "https://gamefocus.co.kr/detail.php?number=147379",
  },
  {
    title: "콘진원, '2023 태국 K박람회' 성료 …2500억 수출 상담액 달성",
    image: '/img/News/img2.png',
    date: "2023.11.13",
    href: "https://www.newspim.com/news/view/20231113000758",
  },
  {
    title: "나인투랩스, 싱가포르 ‘SWITCH 2023’서 NFT 블록체인 게임 ‘드로우샵 킹덤 리버스’ 공개... “PC·모바일 플랫폼에서 즐기는 전략 타이쿤 게임”",
    image: '/img/News/img4.png',
    date: "2023.11.10",
    href: "https://kr.aving.net/news/articleView.html?idxno=1785939",
  },
  {
    title: '조현래 콘진원장 "포켓몬 매출 대부분 이종 연관산업서 나온다"',
    image: '/img/News/img5.png',
    date: "2023.11.29",
    href: "https://news.mt.co.kr/mtview.php?no=2023112917105724771",
  },
  {
    title: '2.5만명이 K콘텐츠에 \'푹\'…"웹툰·게임도 좋아요"',
    image: '/img/News/img6.png',
    date: "2023.11.12",
    href: "https://www.sedaily.com/NewsView/29X7HY5R8Y",
  },
  {
    title: "[TGS 2023] SBA, 서울 소재의 유력 인디 게임 10종 전시.. '종합적-다각적 지원 자신'",
    image: '/img/News/img7.png',
    date: "2023.09.23",
    href: "https://game.donga.com/109094/",
  },
  {
    title: "콘진원, K콘텐츠 파급력 입증…해외진출 연관산업 성과 공개",
    image: '/img/News/img8.png',
    date: "2024.04.18",
    href: "https://www.edaily.co.kr/news/read?newsId=01577686638857432&mediaCodeNo=257&OutLnkChk=Y",
  },
  {
    title: "관계부처합동 해외한류행사 'K-박람회', 올해는 프랑스·인니",
    image: '/img/News/img9.png',
    date: "2024.05.24",
    href: "https://www.newsis.com/view/?id=NISX20240524_0002746847&cID=10701&pID=10700",
  },
  {
    title: "콘진원, 2024 한-아세안 K-콘텐츠 비즈위크 성료",
    image: '/img/News/img10.png',
    date: "2024.05.20",
    href: "https://daily.hankooki.com/news/articleView.html?idxno=1085625",
  },
  {
    title: "나인투랩스, 2024 PlayX4 B2B서 독특한 세계관의 방치형 RPG 게임 ‘좀비키우기’ 알린다... “게임의 매력과 특색 알려 글로벌 진출 가속할 것!”",
    image: '/img/News/img11.png',
    date: "2024.05.20",
    href: "https://kr.aving.net/news/articleView.html?idxno=1790604",
  },
  {
    title: "베스핀글로벌, 나인투랩스에 '옵스나우360' 지원",
    image: '/img/News/img12.png',
    date: "2023.03.09",
    href: "https://www.etnews.com/20230309000178",
  },
  {
    title: "나인투랩스, ‘인형뽑기 타이쿤’ 첫 번째 대규모 업데이트",
    image: '/img/News/img13.png',
    date: "2021.11.09",
    href: "https://www.thisisgame.com/webzine/game/nboard/225/?n=136633",
  },
  {
    title: "나인투랩스, 모바일 게임 ‘인형뽑기 타이쿤’ 정식 출시",
    image: '/img/News/img14.png',
    date: "2021.08.20",
    href: "https://www.dailysecu.com/news/articleView.html?idxno=127869",
  },
];

import Drawer from "rc-drawer";
import styled from "styled-components";

export const NavbarWrapper = styled.nav<{ scrolled: boolean }>`
  width: 100%;
  height: 60px;
  z-index: 9999;
  position: fixed;
  padding: 0 120px;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.scrolled ? "white" : "transparent")};
  transition: background-color 0.3s ease-in-out;

  @media (max-width: 767px) {
    padding: 0 24px;
    background-color: white;
  }
`;

export const NavDrawer = styled(Drawer)`
  & > .rc-drawer-content-wrapper {
    width: 100%;
    height: 420px;
  }
`;

export const LogoImage = styled.img`
  cursor: pointer;
  width: 48px;
  height: 48px;
  @media (max-width: 767px) {
    width: 36px;
    height: 41px;
  }
`;

export const NavbarMenuContainer = styled.ul`
  display: flex;
  gap: 16px;

  @media (max-width: 767px) {
    flex-direction: column;
    position: relative;
    height: 420px;
    width: 100vw;
    top: 75px;
  }
`;

export const NavbarMenuItem = styled.div`
  cursor: pointer;
  padding: 12px 10px;
  gap: 10px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 12px 24px;
  }
`;

export const MenuItemText = styled.p<{ scrolled: boolean }>`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => (props.scrolled ? "#595959" : "#ffffff")};

  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;

    color: #222222;
  }
`;

export const MenuImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const MenuSection = styled.div`
  width: 100%;
  height: 300px;
`;

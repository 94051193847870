import {PartnerContentItem} from "../sections/PartnerSection";


export const PartnerList: PartnerContentItem[] = [
  {
    image: '/img/Partener/sba.png',
  },
  {
    image: '/img/Partener/_zep.png',
  },
  {
    image: '/img/Partener/vespin.png',
  },
  {
    image: '/img/Partener/haechi.png',
  },
  {
    image: '/img/Partener/jm_career.png',
  },
  {
    image: '/img/Partener/hongdae.png',
  },
  {
    image: '/img/Partener/hansei.png',
  },
  {
    image: '/img/Partener/animation_highschool.png',
  },
  {
    image: '/img/Partener/Comakers.png',
  },
  {
    image: '/img/Partener/Agora.png',
  },
  {
    image: '/img/Partener/Sendbird.png',
  },
  {
    image: '/img/Partener/_Nolatency.png',
  },
  {
    image: '/img/Partener/stp.png',
  },
  {
    image: '/img/Partener/K-Startup.png',
  },
  {
    image: '/img/Partener/siwan.png',
  },
  {
    image: '/img/Partener/heume.png',
  },
  {
    image: '/img/Partener/NCloud.png',
  },
  {
    image: '/img/Partener/sejong_univ.png',
  },
  {
    image: '/img/Partener/kocca.png',
  },
  {
    image: '/img/Partener/kk_con.png',
  },
  {
    image: '/img/Partener/hiseoul.png',
  }
];

import styled from "styled-components";

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
export const LayoutContainer = styled.div`
  width: 100%;
  height: 100%;
`;

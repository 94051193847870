import { useMediaQuery } from "react-responsive";
import { SectionMainTitle, SectionTitle } from "../../common/Text";
import {
  AboutSectionWrapper,
  AboutTextWrapper,
  ImageBox,
  IntroductionTextBox,
} from "./styled";
import React from "react";
import { mainSectionImage } from "../../../assets";

export const AboutSection = React.forwardRef<HTMLDivElement>(({}, ref) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <AboutSectionWrapper ref={ref}>
      <IntroductionTextBox>
        <SectionTitle marginBottom="20px">About us</SectionTitle>
        {isMobile ? (
          <>
            <SectionMainTitle marginBottom="30px">
              나인투랩스는 <br />
              변화하는 게임산업에 <br />
              트렌드를 리드하고 <br />
              선도합니다
            </SectionMainTitle>
            <AboutTextWrapper>
              나인투랩스는 글로벌 게임 유행을 새롭게 창조하는 혁신
              <br />
              기업으로 게임과 관련 서비스, 웹 플랫폼의 개발 및<br />
              퍼블리싱 전문 기업입니다. 우리는 최고의 팀을 구성하여,
              <br /> 최고의 게임을 만들고 있습니다. 독점적이고 혁신적인 기술
              <br />로 여러 게임과 플랫폼, 서비스를 계속해서 확장합니다.
              <br /> 현재 100개 이상 파트너사와 기관을 포함한 여러 글로벌
              <br /> 국가의 유저가 즐길 수 있는 블록체인 기반 캐주얼 게임
              <br /> Drawshop Kingdom Reverse을 개발하고 있습니다.
            </AboutTextWrapper>
          </>
        ) : (
          <>
            <SectionMainTitle marginBottom="30px">
              나인투랩스는 변화하는 게임산업에
              <br /> 트렌드를 리드하고 선도합니다
            </SectionMainTitle>
            <AboutTextWrapper>
              나인투랩스는 글로벌 게임 유행을 새롭게 창조하는 혁신 기업으로
              게임과 관련 서비스,
              <br /> 웹 플랫폼의 개발 및 퍼블리싱 전문 기업입니다. 우리는 최고의
              팀을 구성하여,
              <br /> 최고의 게임을 만들고 있습니다. 독점적이고 혁신적인 기술로
              여러 게임과 플랫폼,
              <br /> 서비스를 계속해서 확장합니다. <br />
              현재 100개 이상 파트너사와 기관을 포함한 여러 글로벌 국가의 유저가
              즐길 수 있는
              <br /> 블록체인 기반 캐주얼 게임 Drawshop Kingdom Reverse을
              개발하고 있습니다.
            </AboutTextWrapper>
          </>
        )}
      </IntroductionTextBox>
      <ImageBox src={mainSectionImage} />
    </AboutSectionWrapper>
  );
});

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {SectionMainTitle, SectionMainTitleHTML} from "../../common";
import { ContentBox, MainTitle, TitleWrapper } from "./styled";
import {MainPNG, MainPNG1, MainPNG2, MobileMain, MobileMain2, MobileMain3} from "../../../assets";
import { useMediaQuery } from "react-responsive";


const ImageArr = [
  {
    PCSrc: MainPNG,
    MobileSrc: MobileMain,
    text: "Drawshop Kingdom Reverse 정식버전 런칭",
  },
  {
    PCSrc: MainPNG1,
    MobileSrc: MobileMain2,
    text: "Drawshop Kingdom Reverse 글로벌 런칭",
  },
  {
    PCSrc: MainPNG2,
    MobileSrc: MobileMain3,
    text: "방치형 RPG:<br/>좀비키우기 런칭!",
  },
]

const MainSectionWrapper = styled.div<{ currentImageIndex: number }>`
  width: 100%;
  height: 680px;
  background-image: ${({ currentImageIndex }) =>
    `url(${ImageArr[currentImageIndex].PCSrc})`};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 767px) {
    background-image: ${({ currentImageIndex }) =>
      `url(${ImageArr[currentImageIndex].MobileSrc})`};
    align-items: flex-start;
  }
`;
const MainSection: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const imageChangeTimer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        let tmpIndex = prevIndex + 1;
        if(tmpIndex >= ImageArr.length) {
          tmpIndex = 0;
        }
        return tmpIndex;
      });

    }, 5000);
    return () => clearInterval(imageChangeTimer);
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <MainSectionWrapper currentImageIndex={currentImageIndex}>
      {isMobile ? (
        <ContentBox>
          <TitleWrapper>
            <MainTitle dangerouslySetInnerHTML={{
              __html: ImageArr[currentImageIndex].text,
            }}/>
          </TitleWrapper>
        </ContentBox>
      ) : (
        <ContentBox>
          <TitleWrapper>
            <SectionMainTitleHTML>
              {ImageArr[currentImageIndex].text}
            </SectionMainTitleHTML>
          </TitleWrapper>
        </ContentBox>
      )}
    </MainSectionWrapper>
  );
};
export default MainSection;

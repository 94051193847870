import { useMediaQuery } from "react-responsive";
import { SectionMainTitle } from "../../common";
import { ContentBox, Img, SectionWrapper, TitleBox } from "./styled";

export interface PartnerContentItem {
  image: string;
}

interface ParnerProps {
  partner: PartnerContentItem[];
}

export const PartnerSection: React.FC<ParnerProps> = ({ partner }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <SectionWrapper>
      {isMobile ? (
        <TitleBox>
          <SectionMainTitle marginBottom="40px">
            나인투랩스는 파트너들과
            <br /> 함께 합니다
          </SectionMainTitle>
        </TitleBox>
      ) : (
        <SectionMainTitle marginBottom="54px">
          나인투랩스는 파트너들과 함께 합니다
        </SectionMainTitle>
      )}
      <ContentBox>
        {partner.map(({ image }) => {
          return <Img src={image} />;
        })}
      </ContentBox>
    </SectionWrapper>
  );
};

import styled from "styled-components";
import { colors } from "../../../styles";

export const FooterWrapper = styled.div`
  width: 100%;
  background-color: #555555;
  padding: 80px 120px 100px 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 767px) {
    padding: 60px 24px 80px 24px;
  }
`;

export const ContentBox = styled.div``;

export const Content = styled.div``;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.white};
  margin-bottom: 16px;
`;

export const Description = styled.p`
  font-size: 13px;
  font-weight: 500;
  color: #b1b1b1;
`;

export const Links = styled.div`
  width: 170px;
  display: flex;
  margin-top: 40px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

export const Text = styled.a`
  font-size: 13px;
  font-weight: 500;
  color: #e6e6e6;
  text-decoration: none;
  margin-right: 10px;
`;

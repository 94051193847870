import styled from "styled-components";
import { colors } from "../../../styles";

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 40px 24px 80px 24px;
  }
`;

export const ContentBox = styled.div`
  width: 1200px;
  padding: 60px;
  border-radius: 40px;
  background-color: ${colors.whiteBlue};
  display: flex;
  flex-direction: column;
  padding: 50px;

  @media (max-width: 767px) {
    width: 312px;
    height: 267px;
    padding: 20px 30px 48px 30px;
    justify-content: center;
  }
`;

export const TextBox = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin: 0;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: left;

  margin-right: 20px;

  @media (max-width: 767px) {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin: 0;
  }
`;

export const Contact = styled.a`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.01em;
  text-align: left;
  color: black;
  text-decoration: none;

  @media (max-width: 767px) {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin: 0;
  }
`;

export const PrecautionsBox = styled.div`
  width: auto;
  height: auto;
  @media (max-width: 767px) {
    padding-top: 30px;
  }
`;

export const Precautions = styled.div`
  display: flex;

  @media (max-width: 767px) {
  }
`;

export const Img = styled.img`
  width: 20px;
  height: 20px;

  @media (max-width: 767px) {
    width: 16px;
    height: 16px;
  }
`;

export const Text1 = styled.a`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${colors.darkGray};

  @media (max-width: 767px) {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const TextContainer = styled.a`
  padding-left: 16px;
  position: relative;
  bottom: 3px;
  padding-bottom: 20px;

  @media (max-width: 767px) {
    padding-bottom: 12px;
  }
`;

export const BoldText = styled.a`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;

  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
`;

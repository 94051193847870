import styled from "styled-components";
import { colors } from "../../../styles";

export const SectionTitleWrapper = styled.div<{ marginBottom?: string }>`
  font-family: DM Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${colors.purple};
  margin-bottom: ${(props) => props.marginBottom || "30px"};

  @media (max-width: 767px) {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

export const MainTitleWrapper = styled.div<{ marginBottom?: string }>`
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: ${(props) => props.marginBottom || "30px"};

  @media (max-width: 767px) {
    font-family: Pretendard;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.01em;
    text-align: left;
  }
`;

import { useMediaQuery } from "react-responsive";
import { SectionTitle } from "../../common";
import { precautionsItem } from "../../constants/contact";
import {
  BoldText,
  Contact,
  ContentBox,
  Img,
  Precautions,
  PrecautionsBox,
  SectionWrapper,
  Text1,
  TextBox,
  TextContainer,
  Title,
} from "./styled";
import React from "react";
import { Exclamation } from "../../../assets";

export interface ContactContentItem {
  title?: string;
  contact?: string;
  precautions?: precautionsItem[];
}
interface ContactProps {
  contact: ContactContentItem[];
}

export const ContactSection = React.forwardRef<HTMLDivElement, ContactProps>(
  ({ contact }, ref) => {
    const isMobile = useMediaQuery({
      query: "(max-width: 767px)",
    });
    return (
      <SectionWrapper ref={ref}>
        {isMobile ? (
          <>
            <ContentBox>
              <SectionTitle>Contact us</SectionTitle>
              {contact.map(({ title, contact }, i) => {
                return (
                  <>
                    <TextBox
                      style={
                        i === 1 ? { position: "relative", top: "20px" } : {}
                      }
                    >
                      <Title>{title}</Title>
                      <Contact>{contact}</Contact>
                    </TextBox>
                  </>
                );
              })}
            </ContentBox>
            <PrecautionsBox>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    드로우샵 킹덤 리버스, 하이퍼마켓 등에 대해 나인투랩스는 해
                    <br />당 서비스의 <BoldText>개발</BoldText>만 진행하고
                    있으므로 게임 질문, 파트너십, <br /> 버그 제보 등 사항은
                    해당 배급사에 직접 문의해주시기 바랍니
                    <br />
                    다. ( 배급사는 영문으로만 문의/답변을 진행하므로, 영문으로
                    <br />
                    문의 부탁드립니다. )
                  </Text1>
                </TextContainer>
              </Precautions>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    게임 및 서비스, 라이선스 문의 : 당사가 직접 배급하는 서비스
                    <br />가 아닌 경우가 있으므로, 각 배급사에게 직접
                    문의바랍니다.
                  </Text1>
                </TextContainer>
              </Precautions>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    (일부 게임 및 서비스 한정) 타이틀, 게임IP, 라이선스, 로고를
                    <br />
                    포함한 저작권(사용권)은 해당 유관기업, 기관 및 배급사에게
                    <br />
                    있습니다.
                  </Text1>
                </TextContainer>
              </Precautions>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    저작물을 인용 및 2, 3차 저작물 활용은 당사 또는 해당 배급사
                    <br />에 먼저 사용 가능여부를 문의하시기 바랍니다.
                  </Text1>
                </TextContainer>
              </Precautions>
            </PrecautionsBox>
          </>
        ) : (
          <ContentBox>
            <SectionTitle>Contact us</SectionTitle>
            {contact.map(({ title, contact, precautions }, i) => {
              return (
                <>
                  <TextBox style={i === 2 ? { marginTop: "40px" } : {}}>
                    <Title>{title}</Title>
                    <Contact
                      style={i === 1 ? { marginLeft: "9px" } : {}}
                      href={`mailto:${contact}`}
                    >
                      {contact}
                    </Contact>
                  </TextBox>
                </>
              );
            })}
            <PrecautionsBox style={{ paddingTop: "60px" }}>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    드로우샵 킹덤 리버스, 하이퍼마켓 등에 대해 나인투랩스는 해당
                    서비스의 <BoldText>개발</BoldText>만 진행하고 있으므로
                    <br /> 게임 질문, 파트너십, 버그 제보 등 사항은 해당
                    배급사에 직접 문의해주시기 바랍니다.
                    <br /> ( 배급사는 영문으로만 문의/답변을 진행하므로,
                    영문으로 문의 부탁드립니다. )
                  </Text1>
                </TextContainer>
              </Precautions>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    게임 및 서비스, 라이선스 문의 : 당사가 직접 배급하는
                    서비스가 아닌 경우가 있으므로, 각 배급사에게 직접
                    문의바랍니다.
                  </Text1>
                </TextContainer>
              </Precautions>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    (일부 게임 및 서비스 한정) 타이틀, 게임IP, 라이선스, 로고를
                    포함한 저작권(사용권)은 해당 유관기업, 기관 및 배급사에게
                    있습니다.
                  </Text1>
                </TextContainer>
              </Precautions>
              <Precautions>
                <Img src={Exclamation} />
                <TextContainer>
                  <Text1>
                    저작물을 인용 및 2, 3차 저작물 활용은 당사 또는 해당
                    배급사에 먼저 사용 가능여부를 문의하시기 바랍니다.
                  </Text1>
                </TextContainer>
              </Precautions>
            </PrecautionsBox>
          </ContentBox>
        )}
      </SectionWrapper>
    );
  }
);

import { MainTitleWrapper, SectionTitleWrapper } from "./styled";

interface TextProps {
  children?: React.ReactNode;
  marginBottom?: string;
}

export const SectionTitle: React.FC<TextProps> = ({
  children,
  marginBottom,
}) => {
  return (
    <SectionTitleWrapper marginBottom={marginBottom}>
      {children}
    </SectionTitleWrapper>
  );
};

export const SectionMainTitle: React.FC<TextProps> = ({
                                                        children,
                                                        marginBottom,
                                                      }) => {
  return (
    <MainTitleWrapper marginBottom={marginBottom}>{children}</MainTitleWrapper>
  );
};

export const SectionMainTitleHTML: React.FC<TextProps> = ({
                                                        children,
                                                        marginBottom,
                                                      }) => {
  return (
    <MainTitleWrapper marginBottom={marginBottom} dangerouslySetInnerHTML={{
      __html: children as string,
    }}/>
  );
};

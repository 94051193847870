import {styled} from "styled-components";
import {colors} from "../../../styles";

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.gray};

  @media (max-width: 767px) {
    align-items: flex-start;
  }

  @media (max-width: 1170px) {
    align-items: center;
  }
`;

export const MarginWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 120px;

  @media (max-width: 767px) {
    padding: 60px 24px 80px 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

export const TitleBox = styled.div`
  position: relative;
  right: 130px;
`;

export const ContentBox = styled.div`
  display: grid;
  //가로당 2개씩
  grid-template-columns: repeat(2, 1fr);
  row-gap: 40px;
  column-gap: 40px;
  height: 380px;
  align-items: start;
  @media (max-width: 1170px) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: fit-content;
  }
`;
export const Content = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 1170px) {
    flex-direction: column;
  }
`;
export const ImageBox = styled.a`
  width: 190px;
  height: 100px;
  border-radius: 12px;

  @media (max-width: 1170px) {
    width: 100%;
    height: fit-content;
  }
`;
export const Img = styled.img`
  position: relative;
  width: 190px;
  height: 100px;
  border-radius: 12px;
  object-fit: cover;

  @media (max-width: 1170px) {
    width: 100%;
    height: fit-content;
    aspect-ratio: 312/164;
  }
`;

export const TextBox = styled.div`
  @media (max-width: 767px) {

  }
`;
export const Title = styled.div`
  color: #222;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const Moment = styled.div`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${colors.darkGray};
  margin-top: 10px;

  @media (max-width: 1400px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 10px;

  > .item {
    cursor: pointer;
    color: #666;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    letter-spacing: -0.2px;
  }

  > .item.active {
    color: #7682E7;
  }
`;

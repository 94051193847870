import { ContactContentItem } from "../sections/ContactSection";

export interface precautionsItem {
  image: string;
  text1: string;
  text2?: string;
  text3?: string;
}

export const ContactList: ContactContentItem[] = [
  {
    title: "회사 및 게임/서비스 문의",
    contact: "contact@ninetwolabs.com",
  },
  {
    title: "이외 게임 문의 (배급사)",
    contact: "contact@drawshop.io",
  },
];

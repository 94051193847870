// import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import {
  BusinessSVG1,
  BusinessSVG2,
  ISO인증,
  가족친화인증,
  벤처기업,
  성과공유기업,
  일생활균형캠페인,
  인재육성,
  근무혁신
} from "../../../assets";
import { SectionMainTitle, SectionTitle } from "../../common";
import {
  BusinessText,
  BusinessTextWrapper,
  ContentBox,
  ContentTitle,
  Crape,
  ImageBox,
  ItemBox,
  ItemImage,
  ListTitle,
  MarginWrapper,
  OpacityBox1,
  OpacityBox2,
  SectionWrapper,
  StyleSwiper,
  TextBox,
  WordBreak,
} from "./styled";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";
export interface BusinessTextItem {
  text: string;
}

interface BuisinessProps {
  buisiness: BusinessTextItem[];
}

export const BuisinessSection: React.FC<BuisinessProps> = ({ buisiness }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });

  return (
    <SectionWrapper>
      <MarginWrapper>
        <SectionTitle marginBottom="20px">Business</SectionTitle>
        <SectionMainTitle marginBottom="60px">주요사업과 현황</SectionMainTitle>
        <ContentBox>
          <ItemBox>
            <ImageBox>
              <ItemImage src={BusinessSVG1} />
            </ImageBox>
            {isMobile ? (
              <ContentTitle>PC 및 모바일 게임 개발 및 공급업</ContentTitle>
            ) : (
              <ContentTitle>
                PC 및 모바일 게임
                <br /> 개발 및 공급업
              </ContentTitle>
            )}
          </ItemBox>
          <ItemBox>
            <ImageBox>
              <ItemImage src={BusinessSVG2} />
            </ImageBox>
            {isMobile ? (
              <ContentTitle>블록체인 및 스마트 컨트랙트 자문</ContentTitle>
            ) : (
              <ContentTitle>
                블록체인 및<br /> 스마트 컨트랙트 자문
              </ContentTitle>
            )}
          </ItemBox>
        </ContentBox>
        <TextBox>
          {isMobile ? (
            <BusinessTextWrapper>
              <ListTitle>기업 인증 현황</ListTitle>
              <BusinessText>
                벤처기업협회, 벤처인증기업 (혁신성장부문)
              </BusinessText>
              <BusinessText>ISO 9001, 14001, 45001 인증기업</BusinessText>
              <BusinessText>여성가족부, 가족친화우수기업</BusinessText>
              <BusinessText>고용노동부, 일생활균형우수기업</BusinessText>
              <BusinessText>서울특별시, 2024 하이서울기업</BusinessText>
              <BusinessText>고용노동부, 근무혁신인센티브제 (S등급)</BusinessText>
              <BusinessText>
                과학기술정보통신부, 2022 클라우드 서비스 사용{" "}
                <WordBreak>우수기업</WordBreak>
              </BusinessText>
              <BusinessText>
                한국관광공사, 2022, 2023, 2024 근로자휴가지원사업
                <WordBreak>참여기업</WordBreak>
              </BusinessText>
              <BusinessText>중소벤처기업부, 성과공유기업</BusinessText>
              <BusinessText>중소벤처기업부, 2024 인재육성형 중소기업</BusinessText>
              <BusinessText>
                이외 상표 및 저작권, 블록체인/NFT 기술특허{" "}
                <WordBreak>다수 확보</WordBreak>
              </BusinessText>
            </BusinessTextWrapper>
          ) : (
            <BusinessTextWrapper>
              <ListTitle>기업 인증 현황</ListTitle>
              {buisiness.map(({ text }) => {
                return <BusinessText>{text}</BusinessText>;
              })}
            </BusinessTextWrapper>
          )}
          {isMobile ? (
            <>
              <StyleSwiper
                slidesPerView={6}
                centeredSlides={true}
                loop={true}
                loopedSlides={1}
                speed={1200}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Crape image={가족친화인증} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={일생활균형캠페인} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={성과공유기업} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={벤처기업} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={ISO인증} />
                </SwiperSlide>
              </StyleSwiper>
            </>
          ) : (
            <>
              <StyleSwiper
                slidesPerView={6}
                centeredSlides={true}
                loop={true}
                loopedSlides={2}
                allowTouchMove={false}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {/*<OpacityBox1 />*/}
                <SwiperSlide>
                  <Crape image={가족친화인증} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={일생활균형캠페인} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={성과공유기업} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={벤처기업} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={ISO인증} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={인재육성} />
                </SwiperSlide>
                <SwiperSlide>
                  <Crape image={근무혁신} />
                </SwiperSlide>
              </StyleSwiper>
              <OpacityBox2 />
            </>
          )}
        </TextBox>
      </MarginWrapper>
    </SectionWrapper>
  );
};

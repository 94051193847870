import { useMediaQuery } from "react-responsive";
import { SectionMainTitle, SectionTitle } from "../../common";
import {
  MarginWrapper,
  SectionTextBox,
  VisionSectionWrapper,
  VisionTextWrapper,
} from "./styled";
import React from "react";

export const VisionSection = React.forwardRef<HTMLDivElement>(({}, ref) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)",
  });
  return (
    <VisionSectionWrapper ref={ref}>
      <MarginWrapper>
        <SectionTextBox>
          <SectionTitle marginBottom="20px">Vision</SectionTitle>
          {isMobile ? (
            <>
              <SectionMainTitle marginBottom="60px">
                최고의 팀이
                <br /> 최고의 게임을 만든다
              </SectionMainTitle>
              <VisionTextWrapper>
                나인투랩스팀은 통제 대신 서로 간의 신뢰를 우선합니다.
                <br /> 회사의 문화는 개개인 행동의 합이지, 잘 만들어진 슬라이
                <br />
                드나 문서가 아닙니다. 실수를 하더라도 규칙을 만드는
                <br /> 것에 저항합니다. 제 1 원칙은 유저에게 좋은 제품(게임)과
                <br />
                서비스를 만드는 것이고 모든 하위 룰은 제품을 잘 만들어
                <br />
                내는 하이 퍼포먼스를 위한 것입니다.
                <br /> 제 1 원칙을 달성하기 위한 수단을 위해 각자의 개인 역량을
                <br />
                끝까지 끌어올리고, 원활한 커뮤니케이션을 보장합니다. <br />
                역량을 합치는 과정에서 손실없이, 조화롭게, 더 나아가
                <br /> +a를 만들어내기 위한 다양한 수단을 사용합니다.
              </VisionTextWrapper>
            </>
          ) : (
            <>
              <SectionMainTitle marginBottom="60px">
                최고의 팀이 최고의 게임을 만든다
              </SectionMainTitle>
              <VisionTextWrapper>
                나인투랩스팀은 통제 대신 서로 간의 신뢰를 우선합니다.
                <br /> 회사의 문화는 개개인 행동의 합이지, 잘 만들어진
                슬라이드나 문서가 아닙니다.
                <br /> 실수를 하더라도 규칙을 만드는 것에 저항합니다.
                <br /> 제 1 원칙은 유저에게 좋은 제품(게임)과 서비스를 만드는
                것이고
                <br />
                모든 하위 룰은 제품을 잘 만들어내는 하이 퍼포먼스를 위한
                것입니다.
                <br /> 제 1 원칙을 달성하기 위한 수단을 위해 각자의 개인 역량을
                끝까지 끌어올리고, 원활한 커뮤니케이션을 보장합니다.
                <br /> 역량을 합치는 과정에서 손실없이, 조화롭게, 더 나아가 +a를
                만들어내기 위한 다양한 수단을 사용합니다.
              </VisionTextWrapper>
            </>
          )}
        </SectionTextBox>
      </MarginWrapper>
    </VisionSectionWrapper>
  );
});

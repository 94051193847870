import { useMediaQuery } from "react-responsive";
import { LogoPNG, Menu } from "../../../assets";
import {
  NavbarMenuContainer,
  LogoImage,
  NavbarWrapper,
  NavbarMenuItem,
  MenuItemText,
  MenuImage,
  NavDrawer,
} from "./styeld";
import React, { useState, useEffect } from "react";
import "rc-drawer/assets/index.css";
export interface NavbarMenuItem {
  text: string;
  key: number;
}

interface NvabarProps {
  menu: NavbarMenuItem[];
  onClickMenu?: (index: number) => void;
}

export const Navbar: React.FC<NvabarProps> = ({ menu, onClickMenu }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
        setScrolled(window.scrollY > 0);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width:767px) ",
  });

  const MoveTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const DrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <NavbarWrapper scrolled={scrolled}>
      <LogoImage src={LogoPNG} onClick={MoveTop} />
      {isMobile ? (
        <>
          <MenuImage
            src={Menu}
            onClick={() => {
              setIsDrawerOpen(!isDrawerOpen);
            }}
          />
          <NavDrawer
            placement={"top"}
            open={isDrawerOpen}
            onClose={DrawerClose}
            maskClosable={true}
            width={"100%"}
          >
            <NavbarMenuContainer>
              {menu.map(({ text, key }, i) => (
                <NavbarMenuItem
                  key={key}
                  onClick={() => {
                    onClickMenu && onClickMenu(i);
                    setIsDrawerOpen(false);
                  }}
                >
                  <MenuItemText scrolled={scrolled}>{text}</MenuItemText>
                </NavbarMenuItem>
              ))}
            </NavbarMenuContainer>
          </NavDrawer>
        </>
      ) : (
        <NavbarMenuContainer>
          {menu.map(({ text, key }, i) => (
            <NavbarMenuItem
              key={key}
              onClick={() => onClickMenu && onClickMenu(i)}
            >
              <MenuItemText scrolled={scrolled}>{text}</MenuItemText>
            </NavbarMenuItem>
          ))}
        </NavbarMenuContainer>
      )}
    </NavbarWrapper>
  );
};
